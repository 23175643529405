import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import './modules/i18n/i18n';

const App = lazy(() => import('./App'));

const Root = () => {
  useTranslation();

  return <App />;
};

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(<Root />);
